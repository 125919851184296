<template>
  <div class="main-layout__content">
    <div class="content">
      <h1 class="page-title">Success</h1>
      <div class="container text-center">
        <p>Patreon rewards have been added to your account</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>